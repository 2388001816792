import Vue from 'vue';
// import store from "./store";
// import store from "@/store/index.js";
import { store } from '@/store/index.js';
import Router from 'vue-router';
import Meta from 'vue-meta';

import Body from '../components/body';

import WhitelistPage from '../pages/whitelist_page.vue';
import CallActivityReportPage from '../pages/report/call_activity_report_page.vue';
import IntervalReportPage from '../pages/report/interval_report_page.vue';
import DailyReportPage from '../pages/report/daily_report_page.vue';

//User Management
import UserManagementPage from '../pages/usermanagement_main/usermanagement_page.vue';

//Login Page
import LoginPage from '../pages/login_page.vue';

//Homepage
import Homepage from '../pages/homepage.vue';

//User Settings Page
import UserSettingsPage from '../pages/usersetting_page.vue';

//Setting Page
import SettingPage from '../pages/setting_page.vue';

//Admin Setting
import AdminSetting from '../pages/admin-setting/admin-setting.vue';

//Error Page
import ErrorPage from '../pages/error-page.vue';

import LicenseDetails from '../pages/license-details/license-details.vue';

// component

Vue.use(Router);

const routes = [
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '',
    redirect: { name: 'LoginPage' },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/',
    component: Body,
    children: [
      {
        path: '/home',
        name: 'Homepage',
        component: Homepage,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  // {
  //   path: '/',
  //   component: Body,
  //   children: [
  //     {
  //       path: '/admin-setting',
  //       name: 'AdminSetting',
  //       component: AdminSetting,
  //       meta: {
  //         requiresAuth: true,
  //       },
  //     },
  //   ],
  // },

  {
    path: '/whitelist',
    component: Body,
    children: [
      {
        path: 'whitelistpage',
        name: 'whitelistpage',
        component: WhitelistPage,
        meta: {
          requiresAuth: true,
          permission: 'can_whitelist',
        },
      },
    ],
  },
  {
    path: '/report',
    component: Body,
    children: [
      {
        path: 'callactivityreportpage',
        name: 'callactivityreportpage',
        component: CallActivityReportPage,
        meta: {
          requiresAuth: true,
          permission: 'can_report',
          isdev: false
        },
      },
      {
        path: 'intervalreport',
        name: 'intervalreport',
        component: IntervalReportPage,
        meta: {
          requiresAuth: true,
          permission: 'can_report',
        },
      },
      {
        path: 'dailyreport',
        name: 'dailyreport',
        component: DailyReportPage,
        meta: {
          requiresAuth: true,
          permission: 'can_report',
        },
      },
      {
        path: 'monthly-report',
        name: 'monthlyReport',
        component: ()=> import('../pages/report/monthly-report.vue'),
        meta: {
          requiresAuth: true,
          permission: 'can_report',
          isdev: false
        },
      },
    ],
  },
  {
    path: '/user',
    component: Body,
    children: [
      {
        path: 'user-management',
        component: UserManagementPage,
        meta: {
          requiresAuth: true,
          permission: 'is_admin',
        },
      },
    ],
  },
  {
    path: '/',
    component: Body,
    children: [
      {
        path: 'user-settings',
        component: UserSettingsPage,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/',
    component: Body,
    children: [
      {
        path: 'license-details',
        component: LicenseDetails,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/set',
    component: Body,
    children: [
      {
        path: 'setting',
        component: SettingPage,
        meta: {
          requiresAuth: true,
          permission: 'is_admin',
        },
      },
    ],
  },
  {
    path: '/',
    component: Body,
    children: [
      {
        path: 'error-403',
        component: ErrorPage,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];

const router = new Router({
  routes,
  base: '/',
  mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const email = localStorage.getItem('email');
  // next();

  let token = localStorage.getItem('token');
  let isExp = await isTokenExpired();
  let pagePermission = to.meta.permission;
  let userPerLocal = localStorage.getItem('permission');
  let userPermission = userPerLocal || '';

  let isAdmin = userPermission.includes('is_admin');
  let result = null;

  if (isAdmin) {
    result = true;
  } else {
    result = userPermission.includes(pagePermission);
  }

  if (token) {
    if (!isExp) {
      if (to.path == '/login') {
        next('/home');
      } else {
        switch (true) {
          case to.meta.isdev:
            if (!email.includes('cloudsoft')) {
                  next('/error-403');
                } else {
                  next();
                }
                break;
          case to.meta.permission === undefined:
            next();
            break;
          case !result:
            next('/error-403');
            break;
          default:
            next();
            break;
        }
      }
    } else {
      localStorage.clear();
      if (to.path != '/login') {
        next('/login');
      } else {
        next();
      }
    }
  } else {
    localStorage.clear();
    if (to.path != '/login') {
      next('/login');
    } else {
      next();
    }
  }
});

function isTokenExpired() {
  const decodedToken = localStorage.getItem('exp');
  const currentTime = Date.now() / 1000;
  return decodedToken < currentTime;
}

export default router;
