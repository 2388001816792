<template>
  <div class="header-wrapper row m-0">
    <!-- =============================== search_open() =============================== -->
    <div class="header-logo-wrapper">
      <div class="logo-wrapper">
        <router-link to="/home">
          <img class="img-fluid" src="../assets/images/logo/logo.png" alt />
        </router-link>
      </div>
      <div class="toggle-sidebar" @click="toggle_sidebar">
        <feather
          class="status_toggle middle sidebar-toggle"
          type="sliders"
          id="sidebar-toggle"
        ></feather>
      </div>
    </div>
    <div class="left-header col horizontal-wrapper pl-0"></div>
    <div class="nav-right col-8 pull-right right-header p-0">
      <ul class="nav-menus">
        <li class="profile-nav onhover-dropdown p-0 mr-0">
          <div class="media-body">
            <p class="m-0">{{ userName }} <i class="fa fa-angle-down" /></p>
          </div>
          <ul class="profile-dropdown onhover-show-div p-0">
            <li @click="goToPage('/license-details')">
              <a>
                <font-awesome-icon icon="fa-solid fa-id-badge" />
                <span>License Details</span></a
              >
            </li>
            <li @click="goToPage('/user-settings')">
              <a>
                <feather type="settings"></feather>
                <span>การตั้งค่าบัญชี</span></a
              >
            </li>
            <li @click="logout">
              <a><feather type="log-in"></feather><span>ลงชื่อออก</span></a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <script class="result-template" type="text/x-handlebars-template">
      <div class='ProfileCard u-cf'>
        <div class='ProfileCard-avatar'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
            class='feather feather-airplay m-0'
          >
            <path
              d='M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1'
            ></path>
            <polygon points='12 15 17 21 7 21 12 15'></polygon>
          </svg>
        </div>
        <div class='ProfileCard-details'>
          <div class='ProfileCard-realName'>
            name
          </div>
        </div>
      </div>
    </script>
  </div>
</template>
<script>
var body = document.getElementsByTagName('body')[0];
import Bookmark from './bookmark';
import { mapState } from 'vuex';
export default {
  name: 'Search',
  data() {
    return {
      terms: '',
      searchOpen: false,
      searchResult: false,
      searchResultEmpty: false,
      close_sidebar_var: false,
      clicked: false,
      mobile_toggle: false,
      mobile_search: false,
      openbonusUI: false,
      openLevelmenu: false,
      openlanguage: false,
      mobile_accordian: false,
      mixLayout: 'light-only',
    };
  },
  components: {
    Bookmark,
  },
  computed: {
    ...mapState({
      menuItems: (state) => state.menu.searchData,
      megamenuItems: (state) => state.menu.megamenu,
    }),
    userName() {
      return (
        localStorage.getItem('firstname') +
        ' ' +
        localStorage.getItem('lastname')
      );
    },
  },
  methods: {
    goToPage(path) {
      this.$router.push(path);
    },
    logout() {
      localStorage.clear();
      this.$router.push('/login');
    },
    toggle_sidebar() {
      this.$store.dispatch('menu/opensidebar');
    },
    setNavActive(item) {
      this.$store.dispatch('menu/setBonusNavActive', item);
    },
    openlangpicker() {
      this.openlanguage = !this.openlanguage;
    },
    openlevelmenu() {
      this.openLevelmenu = !this.openLevelmenu;
    },
    openmegamenu() {
      this.openbonusUI = !this.openbonusUI;
    },
    closeBonusUI() {
      this.openbonusUI = false;
    },
    search_open() {
      this.searchOpen = true;
    },
    search_close() {
      this.searchOpen = false;
    },
    searchterm: function () {
      this.$store.dispatch('menu/searchTerm', this.terms);
    },
    changeLocale(locale) {
      this.setLang(locale);
    },
    mobileaccordian() {
      this.mobile_accordian = !this.mobile_accordian;
    },
    addFix() {
      body.classList.add('offcanvas');
      this.searchResult = true;
    },
    removeFix() {
      body.classList.remove('offcanvas');
      this.searchResult = false;
      this.terms = '';
    },
    toggle_fullscreen() {
      if (
        (document.fullScreenElement && document.fullScreenElement !== null) ||
        (!document.mozFullScreen && !document.webkitIsFullScreen)
      ) {
        if (document.documentElement.requestFullScreen) {
          document.documentElement.requestFullScreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullScreen) {
          document.documentElement.webkitRequestFullScreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    customizeMixLayout(val) {
      this.mixLayout = val;
      this.$store.dispatch('layout/setLayout', val);
    },
  },
  watch: {
    '$i18n.locale'(to, from) {
      if (from !== to) {
        this.$router.go(this.$route.path);
      }
    },
    menuItems: function () {
      this.terms ? this.addFix() : this.removeFix();
      if (!this.menuItems.length) this.searchResultEmpty = true;
      else this.searchResultEmpty = false;
    },
  },
};
</script>

<style scoped>
ul.profile-dropdown.onhover-show-div {
  top: 30px !important;
  left: auto !important;
  width: 10vw !important;
  margin: 0 !important;
}

.nav-menus {
  font-size: 10px;
}

.nav-menus:hover {
  color: #3f60fb;
  transition: 0.5s;
}
</style>
