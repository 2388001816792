<template>
  <div>
    <Breadcrumbs title="License Details" />
    <div class="container-fluid card py-3 px-4" style="height: 25dvh">
      <div class="d-flex align-items-center">
        <font-awesome-icon
          icon="id-badge"
          class="mr-2"
          style="font-size: 20px"
        />
        <span style="font-size: 16px">License</span>
      </div>
      <hr class="my-3" />
      <div class="d-flex" style="gap: 25px">
        <div style="width: 30%">
          <div class="box">
            <span class="topic">Type</span>
            <span>Smart Call subscription</span>
          </div>
          <div class="box">
            <span class="topic">License:</span>
            <span>{{ item.LicenseCount }} Users</span>
          </div>
          <div class="box">
            <span class="topic">Contract period:</span>
            <span>{{ formattedDate(item.start_time) }} - {{ formattedDate(item.end_time) }}</span>
          </div>
        </div>

        <div style="width: 25%">
          <div class="topic"><span>End User Information</span></div>
          <div class="box">
            <span class="topic">Company Name: </span>
            <span>{{ item.CompanyName }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { requestService } from '@/_services/request.service';

export default {
  data() {
    return {
      item:{}
    };
  },
  mounted() {
    this.loadItems()
  },
  methods: {
    loadItems(){
      let url = `/auth/license`
      requestService
        .GetFetch(url)
        .then((response) => {
          this.item = response.data.data
        })
        .catch((error) => {
          console.error(error);
        });
    },
    formattedDate(date) {
      if(date){
        const [year, month, day] = date.split('-');
        return `${day}/${month}/${year}`;
      }else{
        return ''
      }
    }
  },
};
</script>

<style scoped>
.topic {
  font-weight: 600;
}

.box {
  display: flex;
  justify-content: space-between;
}
</style>
