<template>
  <div>
    <Breadcrumbs title="รายงานการโทร" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div
              class="row justify-content-start p-b-0"
              style="padding-top: 1px !important"
            >
              <div class="col-4 p-l-30 p-r-20 p-b-0" style="height: 64px">
                <label class="col-form-label" style="font-size: 10px"
                  >วันที่เริ่มต้น</label
                >
                <div class="col-sm-12 p-l-0 p-r-0">
                  <b-form-input
                    v-model="start_date_first"
                    type="date"
                    size="sm"
                    :min="minStartDate"
                    @input="onStartDateChange"
                    style="font-size: 10px; cursor: pointer"
                  ></b-form-input>
                </div>
              </div>

              <div class="col-4 p-l-0 p-r-20 p-b-0" style="height: 64px">
                <label class="col-form-label" style="font-size: 10px"
                  >วันที่สิ้นสุด</label
                >
                <div class="col-sm-12 p-l-0 p-r-0">
                  <b-form-input
                    v-model="end_date_first"
                    type="date"
                    value=""
                    size="sm"
                    :min="minEndDate"
                    :max="maxEndDate"
                    style="font-size: 10px; cursor: pointer"
                  ></b-form-input>
                </div>
              </div>

              <div class="col-2 pl-0">
                <label class="col-form-label" style="font-size: 10px"
                  >เบอร์ต้นทางหรือเบอร์ปลายทาง</label
                >
                <b-form-input
                  v-model="phone"
                  type="number"
                  id="phoneClass"
                  class="inputNum"
                  style="font-size: 10px; cursor: pointer"
                />
              </div>

              <!-- ========== Button ========== -->
              <div
                class="row col-2"
                style="margin: 0 0px 0 0px; padding: 0 1px 0 0px"
              >
                <div class="col-12 d-flex align-items-center p-l-0">
                  <b-button
                    @click="onSearchAll"
                    type="button"
                    variant="primary"
                    id="search-one-callactivity-btn-z"
                    class="btn btn-pill btn-xs"
                    style="width: 90px; height: 24px; margin: 9px 15px 0 0"
                  >
                    ค้นหา
                  </b-button>

                  <b-button
                    @click="clearFilterAll"
                    type="button"
                    variant="danger"
                    id="clear-one-callactivity-btn-z"
                    class="btn btn-pill btn-xs h-2"
                    style="width: 90px; height: 24px; margin: 9px 15px 0 0"
                  >
                    ล้าง
                  </b-button>
                </div>
              </div>
              <!-- ========== Button ========== -->
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <b-row class="justify-content-end">
                <b-row
                  class="row mb-3"
                  style="margin: 0 0 0 0; padding: 0 1px 0px 0"
                >
                  <b-col class="d-flex">
                    <filterCallActivity
                      class="d-flex"
                      @onSearch="onSearch"
                      @clear="clearFilter"
                    />
                    <!-- ========== Import CSV ========== -->
                    <b-row class="row">
                      <b-col class="" style="margin: 0 0px 0 0px">
                        <b-button
                          @click="getExportCSVCallActivity"
                          id="export-csv-callactivity-btn-z"
                          class="btn btn-pill btn-xs"
                          size="sm"
                          variant="primary"
                          :disabled="exportBtn"
                          :style="{
                            backgroundColor: exportBtn
                              ? '#d0d4ca !important'
                              : '',
                            border: exportBtn ? 'none' : '',
                          }"
                        >
                          <b-spinner v-if="isExport == 100" small></b-spinner>
                          <div v-if="isExport == 0">
                            <font-awesome-icon icon="fa-solid fa-file-export" />
                            ส่งออก
                          </div>
                        </b-button>
                        <exportData ref="exportActivity" />
                      </b-col>
                    </b-row>

                    <b-row class="row">
                      <b-col style="margin: 0 0px 0 0px">
                        <b-form-select
                          v-model="perPage"
                          :options="pageOptions"
                          class="btn-xs"
                          style="
                            width: 55px;
                            height: 24px;
                            margin: 2px 0px 0 15px;
                            font-size: 10px !important;
                          "
                        >
                        </b-form-select>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-row>

              <div class="daily-report table-responsive">
                <v-data-table
                  :headers="headers"
                  :items="rows"
                  :page.sync="currentPage"
                  hide-default-footer
                  :items-per-page="perPage"
                  @page-count="pageCount = $event"
                  class="elevation-1"
                  :loading="isLoading"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  @update:sort-by="onSorted"
                  @update:sort-desc="onSorted"
                >
                  <template v-slot:loading>
                    <v-progress-circular
                      indeterminate
                      color="primary"
                      size="40"
                      class="ma-2"
                    ></v-progress-circular>
                  </template>
                  <template slot="no-data"> ไม่มีข้อมูล.. </template>
                  <template v-slot:item.call_start_time="{ item }">
                    <div
                      class="text-center"
                      style="width: 140px; font-size: 10px"
                    >
                      {{ item.call_start_time }}
                    </div>
                  </template>
                  <template v-slot:item.call_end_time="{ item }">
                    <div
                      class="text-center p-0 m-0"
                      style="width: 140px; font-size: 10px"
                    >
                      {{ item.call_end_time }}
                    </div>
                  </template>
                  <template v-slot:item.direction="{ item }">
                    <div
                      class="text-center"
                      style="width: 120px; font-size: 10px"
                    >
                      {{ item.direction }}
                    </div>
                  </template>
                  <template v-slot:item.src="{ item }">
                    <div
                      class="text-center"
                      style="width: 120px; font-size: 10px"
                    >
                      {{ item.src }}
                    </div>
                  </template>

                  <template v-slot:item.dst="{ item }">
                    <div
                      class="text-center"
                      style="width: 120px; font-size: 10px"
                    >
                      {{ item.dst }}
                    </div>
                  </template>
                  <template v-slot:item.status="{ item }">
                    <div
                      class="text-center"
                      style="width: 140px; font-size: 10px"
                    >
                      {{ item.status }}
                    </div>
                  </template>
                  <template v-slot:item.ring="{ item }">
                    <div
                      class="text-center"
                      style="width: 130px; font-size: 10px"
                    >
                      {{ item.ring }}
                    </div>
                  </template>
                  <template v-slot:item.talk="{ item }">
                    <div
                      class="text-center"
                      style="width: 150px; font-size: 10px"
                    >
                      {{ item.talk }}
                    </div>
                  </template>
                  <template v-slot:item.forward="{ item }">
                    <div
                      class="text-center"
                      style="width: 130px; font-size: 10px"
                    >
                      {{ item.forward }}
                    </div>
                  </template>
                </v-data-table>
                <div class="text-center pt-2 sm">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="center"
                    @input="getFiltersCallActivity"
                    class="justify-content-center"
                    size="sm"
                    aria-controls="my-table"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import { requestService } from '@/_services/request.service';
import exportData from '@/components/export.vue';
import filterCallActivity from '@/components/report/filter-call-activity.vue';

export default {
  components: {
    exportData,
    filterCallActivity,
  },
  data() {
    return {
      isDisabled: true,
      exportBtn: false,
      // ========== Filter ==========
      today: new Date(),
      start_date_first: new Date().toISOString().substr(0, 10),
      end_date_first: new Date().toISOString().substr(0, 10),
      call_start_time_value: '',
      call_end_time_value: '',
      callid: '',
      call_start_time: '',
      call_end_time: '',
      src: '',
      dst: '',
      directionFilter: '',
      // agent_name: '',
      ring: '',
      talk: '',
      department: '',
      callerId: '',
      isExport: 0,
      selectedFilterRing: null,
      optionsFilterDirection: [
        { value: '', text: 'Choose' },
        { value: 'in', text: 'Inbound' },
        { value: 'out', text: 'Outbound' },
        // { value: '<', text: '<' },
      ],
      optionsFilterRing: [
        { value: null, text: 'Choose' },
        { value: '=', text: '=' },
        { value: '>', text: '>' },
        { value: '<', text: '<' },
      ],
      selectedFilterTalk: null,
      optionsFilterTalk: [
        { value: null, text: 'Choose' },
        { value: '=', text: '=' },
        { value: '>', text: '>' },
        { value: '<', text: '<' },
      ],
      // ========== Filter ==========
      rows: [],
      tableData: [],
      filter: null,
      totalRows: 0,
      totalPages: 1,
      currentPage: 1,
      page: 1,
      perPage: 20,
      pageOptions: [10, 20, 30, 40, 50, 100],
      sortBy: 'call_start_time desc',
      sortDesc: false,
      sortDirection: 'asc',
      modalVisibleDaily: false,
      headers: [
        {
          text: 'Call Start Time',
          value: 'call_start_time',
        },
        {
          text: 'Call End Time',
          value: 'call_end_time',
        },
        {
          text: 'Direction',
          value: 'direction',
        },
        {
          text: 'From',
          value: 'src',
        },
        {
          text: 'To',
          value: 'dst',
        },
        {
          text: 'Status',
          value: 'status',
        },
        {
          text: 'Ring Time',
          value: 'ring',
        },
        {
          text: 'Talk Time',
          value: 'talk',
        },
        {
          text: 'Forward',
          value: 'forward',
        },
        {
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
      ],
      isLoading: false,
      phone: '',
      sorting: 'call_start_time desc',
      isSort: false,
    };
  },
  computed: {
    minStartDate() {
      let minDate = new Date();
      minDate.setDate(this.today.getDate() - 90);
      return this.formatDate(minDate);
    },
    minEndDate() {
      if (this.start_date_first) {
        return this.start_date_first;
      } else {
        let minDate = new Date();
        minDate.setDate(this.today.getDate() - 90);
        return this.formatDate(minDate);
      }
    },
    maxEndDate() {
      if (this.start_date_first) {
        let maxDate = new Date(this.start_date_first);
        maxDate.setDate(maxDate.getDate() + 7);
        return this.formatDate(maxDate);
      }
      return null;
    },
    formattedStartDate() {
      if (this.start_date_first) {
        const start_date_first = new Date(this.start_date_first);
        const year = start_date_first.getFullYear();
        const month = String(start_date_first.getMonth() + 1).padStart(2, '0');
        const date = String(start_date_first.getDate()).padStart(2, '0');

        let time = '00:00:00';
        if (this.call_start_time_value) {
          time = this.call_start_time_value + ':00';
        }

        // ผนวก "YYYY-MM-DDTHH:mm:ss"
        return `${year}-${month}-${date}T${time}`;
      }
      return '';
    },
    formattedEndDate() {
      // แปลงค่าเป็น "YYYY-MM-DDTHH:mm:ss"
      // console.log('this.call_end_time_value: ', this.call_end_time_value);
      if (this.end_date_first) {
        const end_date_first = new Date(this.end_date_first);
        const year = end_date_first.getFullYear();
        const month = String(end_date_first.getMonth() + 1).padStart(2, '0');
        const date = String(end_date_first.getDate()).padStart(2, '0');
        let time = '23:59:59';
        if (this.call_end_time_value != '') {
          time = this.call_end_time_value + ':00';
        } else {
          let time = '23:59:59';
        }

        // ผนวก "YYYY-MM-DDTHH:mm:ss"
        return `${year}-${month}-${date}T${time}`;
      }
      return '';
    },
  },

  async mounted() {
    this.totalRows = this.rows.length;
    this.total_pages = this.rows.length;
    // this.getFiltersCallActivity();
    this.permissionUpdate();
  },
  watch: {
    // phone(newVal) {
    //   if (newVal) {
    //     this.isDisabled = false;
    //   } else {
    //     this.isDisabled = true;
    //   }
    // },
    perPage(newVal) {
      this.getFiltersCallActivity();
    },
  },
  methods: {
    formatDate(date) {
      return date.toISOString().split('T')[0];
    },
    onStartDateChange() {
      if (this.end_date_first) {
        let minValid = new Date(this.start_date_first);
        let maxValid = new Date(this.start_date_first);
        maxValid.setDate(maxValid.getDate() + 7);
        let selectedEndDate = new Date(this.end_date_first);

        if (selectedEndDate < minValid || selectedEndDate > maxValid) {
          this.end_date_first = null;
        }
      }
    },
    onSearchAll() {
      this.currentPage = 1;
      this.getFiltersCallActivity();
    },
    onSearch(e) {
      // if (this.isDisabled) {
      //   this.$swal.fire({
      //     icon: 'error',
      //     title: 'กรุณากรอกเบอร์ต้นทางหรือปลายทาง',
      //   });
      // } else {
      this.currentPage = 1;
      this.call_start_time_value = e.call_start_time_value;
      this.call_end_time_value = e.call_end_time_value;
      this.call_start_time = e.call_start_time;
      this.call_end_time = e.call_end_time;
      this.src = e.src;
      this.dst = e.dst;
      this.directionFilter = e.directionFilter;
      this.selectedFilterRing = e.selectedFilterRing;
      this.selectedFilterTalk = e.selectedFilterTalk;
      this.ring = e.ring;
      this.talk = e.talk;
      this.department = e.department;
      this.callerId = e.callerId;
      this.getFiltersCallActivity();
      // }
    },
    async onSorted() {
      if (!this.isSort) {
        this.isSort = true;
        let by = '';
        if (!this.sortBy) {
          by = this.sorting.replace(/\s?(asc|desc)$/, '');
        } else {
          by = this.sortBy;
        }
        let sortType = 'asc';
        switch (this.sortDesc) {
          case true:
            sortType = 'desc';
            break;
          case false:
            sortType = 'asc';
            break;
        }
        this.sorting = by + ' ' + sortType;
        await this.getFiltersCallActivity();
        this.isSort = false;
      }
    },
    async getExportCSVCallActivity() {
      if (this.isExport == 0) {
        this.isExport = 100;
        let start_date_first = this.formattedStartDate;
        let end_date_first = this.formattedEndDate;

        var url = `/reports/?time_start=${start_date_first}&time_end=${end_date_first}&limit=${1000}&call_start_time=${
          this.call_start_time
        }&call_end_time=${this.call_end_time}&src=${this.src}&dst=${
          this.dst
        }&ring=${this.ring}${
          this.selectedFilterRing != null
            ? '&ring_op=' + this.selectedFilterRing
            : ''
        }&talk=${this.talk}${
          this.selectedFilterTalk != null
            ? '&talk_op=' + this.selectedFilterTalk
            : ''
        }&department=${this.department}&root_call=${this.callerId}&direction=${
          this.directionFilter
        }&phone=${this.phone}&sort=${this.sortBy}`;
        let header =
          'Call Start Time,Call End Time,Direction,From,To,Status,Ring Time,Talk Time,Forward';
        // this.$refs.exportActivity.getExportCSV(url);
        await this.$refs.exportActivity.loopGetData(
          url,
          'call_activity_report.csv',
          header,
          'callActivity'
        );
        // setTimeout(()=>{
        this.isExport = 0;
      }
      // }, 2000)
    },
    permissionUpdate() {
      const permission = localStorage.getItem('permission');
      let result =
        permission.includes('can_export_report') ||
        permission.includes('is_admin');
      if (!result) {
        this.exportBtn = true;
      } else {
        this.exportBtn = false;
      }
    },
    formatTime(time) {
      const dateObject = new Date(time);
      const year = dateObject.getFullYear();
      const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-indexed
      const day = dateObject.getDate().toString().padStart(2, '0');
      const hours = dateObject.getHours().toString().padStart(2, '0');
      const minutes = dateObject.getMinutes().toString().padStart(2, '0');
      const seconds = dateObject.getSeconds().toString().padStart(2, '0');
      const formattedDateString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

      return formattedDateString;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = page;
    },
    onPageChange(page) {
      this.currentPage = page;
      this.getFiltersCallActivity();
    },
    clearFilter() {
      // if (this.isDisabled) {
      //   this.$swal.fire({
      //     icon: 'error',
      //     title: 'กรุณากรอกเบอร์ต้นทางหรือปลายทาง',
      //   });
      // } else {
      this.filter = '';
      this.call_start_time = '';
      this.call_end_time = '';
      this.src = '';
      this.dst = '';
      this.ring = '';
      this.talk = '';
      this.call_start_time_value = '';
      this.call_end_time_value = '';
      this.selectedFilterRing = null;
      this.selectedFilterTalk = null;
      this.directionFilter = '';
      this.department = '';
      this.callerId = '';
      this.currentPage = 1;
      this.getFiltersCallActivity();
      // }
    },
    clearFilterAll() {
      this.start_date_first = new Date().toISOString().substr(0, 10);
      this.end_date_first = new Date().toISOString().substr(0, 10);
      this.phone = '';
      this.perPage = 20;
      this.clearFilter();
      // this.getFiltersCallActivity();
    },
    getFiltersCallActivity() {
      let start_date_first = this.formattedStartDate;
      let end_date_first = this.formattedEndDate;

      let errorMessage = '';
      if (!start_date_first && !end_date_first) {
        errorMessage = 'กรุณาเลือกวันที่เริ่มต้นและวันที่สิ้นสุด';
      } else if (!start_date_first) {
        errorMessage = 'กรุณาเลือกวันที่เริ่มต้น';
      } else if (!end_date_first) {
        errorMessage = 'กรุณาเลือกวันที่สิ้นสุด';
      }

      if (errorMessage) {
        this.$swal.fire({
          icon: 'error',
          title: errorMessage,
          timer: 4000,
          showConfirmButton: false,
        });
        return '';
      }

      this.rows = [];
      this.isLoading = true;

      var url = `/reports/?time_start=${start_date_first}&time_end=${end_date_first}&page=${
        this.currentPage
      }&limit=${this.perPage}&call_start_time=${
        this.call_start_time
      }&call_end_time=${this.call_end_time}&ring=${this.ring}${
        this.selectedFilterRing != null
          ? '&ring_op=' + this.selectedFilterRing
          : ''
      }&talk=${this.talk}${
        this.selectedFilterTalk != null
          ? '&talk_op=' + this.selectedFilterTalk
          : ''
      }&direction=${this.directionFilter}&department=${
        this.department
      }&root_call=${this.callerId}&phone=${this.phone}&sort=${this.sorting}`;
      requestService
        .GetFetch(url)
        .then((response) => {
          // console.log('filter: ', response.data.data.rows);
          let newArr = response.data.data.rows.map((e) => {
            let direct = e.direction == 'In' ? 'Inbound' : 'Outbound';
            let obj = {
              call_start_time: this.formatTime(e.call_start_time),
              call_end_time: this.formatTime(e.call_end_time),
              direction: direct,
              src: e.src,
              dst: e.dst,
              ring: e.ring,
              talk: e.talk,
              forward: e.forward,
              status: e.status,
            };
            return obj;
          });
          this.rows = newArr;
          this.totalRows = response.data.data.total_rows;
          this.totalPages = response.data.data.total_pages;
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style scoped>
.disabled-btn {
  cursor: not-allowed !important;
  opacity: 0.5;
}
input#phoneClass {
  height: 25px !important;
}

.inputNum::-webkit-inner-spin-button,
inputNum::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
<style>
#export-csv-callactivity-btn-z {
  width: 82px;
  height: 24px;
  margin: 2px 0 0 0;
}
.three-dots-vertical {
  color: #434343ae;
}

.no-border {
  background-color: transparent;
  border: none;
}

.search-filter-whitlist-z {
  font-size: 5px;
  padding: 0.1rem 0.05rem;
}

#clear-btable-callactivity-btn-z {
  color: #f83366;
  background-color: #feeaef !important;
  border-color: #feeaef !important;
}

#filter-callactivity-btn-z {
  width: 82px;
  height: 24px;
  margin: 1px 0 0 0;
}

#search-one-callactivity-btn-z {
  color: #3f60fb;
  background-color: #dfedff !important;
  border-color: #dfedff !important;
}
#searchbtn {
  color: #3f60fb;
  background-color: #dfedff !important;
  border-color: #dfedff !important;
}
#clear-one-callactivity-btn-z {
  color: #f83366;
  background-color: #feeaef !important;
  border-color: #feeaef !important;
}
#clearFilter {
  color: #f83366;
  background-color: #feeaef !important;
  border-color: #feeaef !important;
}

/* #search-filter-callactivity-btn-z {
    } */

/* ==================== b-table call_activity_report_page ==================== */
div.call-activity-report table.call-activity-report thead tr th {
  width: auto;
  padding: 2px 1px 2px 1px !important;
  /* margin: ; */
}
div.call-activity-report table.call-activity-report thead tr th div {
  font-size: 10px;
}
div.call-activity-report table.call-activity-report tbody tr td {
  /* width: ; */
  width: auto;
  padding: 2px 10px 2px 10px;
  /* margin: ; */
}
div.call-activity-report table.call-activity-report tbody tr td div {
  font-size: 10px;
}

.call_id-call {
  text-align: center;
}
.call_start_time-call {
  text-align: center;
  /* width: max-content !important; */
}
.call_end_time-call {
  text-align: center;
  /* width: max-content !important; */
}
.from-call {
  text-align: center;
}
/* .status-call {
    text-align: center;
  } */
.to-call {
  text-align: center;
}
.ring-call {
  text-align: center;
  /* width: 70px; */
  /* margin: 0px -6px 0px 5px !important; */
}
.talk-call {
  text-align: center;
  /* width: 70px; */
  /* margin: 0px -6px 0px 5px !important; */
}
/* ==================== b-table call_activity_report_page ==================== */

/* ========== dropdown b-table ========== */
.dropdown-toggle {
  padding: initial !important;
  height: 15px;
}
/* ========== dropdown b-table ========== */

/* ========== Filter dropdown-menu ========== */
/* div#b-dropdown-filter-callactivity-z ul.dropdown-menu.show {
    margin: 16px 0px 0px -203px;
  } */

.filter-border-z {
  color: #388eff;
  border-bottom: 1px solid #1279ff;
  border-bottom-width: 2px;
  margin: 0 0 0 0;
  height: 23px;
}

#search_filter_callactivity_start-time {
  border-radius: 3px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2),
    0px 0.10000000149011612px 0.30000001192092896px 0px rgba(0, 0, 0, 0.1);
}
#search_filter_callactivity_end-time {
  border-radius: 3px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2),
    0px 0.10000000149011612px 0.30000001192092896px 0px rgba(0, 0, 0, 0.1);
}
#search_filter_callactivity_from {
  border-radius: 3px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2),
    0px 0.10000000149011612px 0.30000001192092896px 0px rgba(0, 0, 0, 0.1);
}
#search_filter_callactivity_to {
  border-radius: 3px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2),
    0px 0.10000000149011612px 0.30000001192092896px 0px rgba(0, 0, 0, 0.1);
}
#search_filter_callactivity_agent-name {
  border-radius: 3px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2),
    0px 0.10000000149011612px 0.30000001192092896px 0px rgba(0, 0, 0, 0.1);
}
#search_filter_callactivity_ring-time1 {
  border-radius: 3px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2),
    0px 0.10000000149011612px 0.30000001192092896px 0px rgba(0, 0, 0, 0.1);
}
#search_filter_callactivity_ring-time2 {
  border-radius: 3px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2),
    0px 0.10000000149011612px 0.30000001192092896px 0px rgba(0, 0, 0, 0.1);
}
#search_filter_callactivity_talk-time1 {
  border-radius: 3px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2),
    0px 0.10000000149011612px 0.30000001192092896px 0px rgba(0, 0, 0, 0.1);
}
#search_filter_callactivity_talk-time2 {
  border-radius: 3px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2),
    0px 0.10000000149011612px 0.30000001192092896px 0px rgba(0, 0, 0, 0.1);
}
/* ========== Filter dropdown-menu ========== */
</style>
