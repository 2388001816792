<template>
  <div>
    <!-- Import CSV -->
<b-modal hide-footer hide-header-close hide-header centered size="sm"  id="loading-modal" title="BootstrapVue">
  <div class='d-flex justify-content-center'>
    <b-spinner  id='testmodal' variant="primary" class='my-3' style='height: 100px; width: 100px'/>
  </div>
</b-modal>
    <b-button
      v-if="!btnImport"
      v-b-modal.modal-lg-import-csv-whitelist
      id="import-csv-whitlist-btn-z"
      class="btn btn-pill btn-xs"
      style='color: white'
      type=""
      size="sm"
      variant="primary"
    >
      <font-awesome-icon icon="fa-solid fa-file-import" /> นำเข้า
    </b-button>
    <b-button
      v-if="btnImport"
      id="import-csv-whitlist-btn-z"
      class="btn btn-pill btn-xs"
      type=""
      size="sm"
      variant="primary"
      disabled
      style="background-color: #d0d4ca !important; border: none !important"
    >
      <font-awesome-icon icon="fa-solid fa-file-import" /> Import
    </b-button>
    <b-modal
      hide-footer
      id="modal-lg-import-csv-whitelist"
      size="lg"
      title="Import CSV"
    >
      <b-row class="justify-content-between">
        <b-col style="padding: 0 14px 0 12px">
          <b-form-file
            v-model="file"
            @change="handleFileUploadWhitelist"
            accept=".csv"
            ref="file-input"
            class=""
            style="
              width: 560px;
              margin: 0 0px 10px 0 !important;
              font-size: 8px;
              cursor: pointer;
            "
          >
          </b-form-file>
          <p
            style="font-size: 8px; cursor: pointer; color: #3f60fb"
            @click="downloadTemplate"
          >
            Download Template
          </p>
        </b-col>

        <b-row class="row">
          <b-col class="d-flex">
            <!-- mr-2 -->
            <b-button
              @click="postImportCSVWhitelist"
              id="submit-import-whitlist-btn-z"
              class="btn btn-pill btn-xs"
              size="sm"
              style="width: 90px; height: 24px; margin: 6px 15px 0 0"
            >
              Submit
            </b-button>

            <b-button
              @click="clearFilesCSVWhitelist"
              id="clear-import-whitlist-btn-z"
              class="btn btn-pill btn-xs"
              size="sm"
              style="width: 90px; height: 24px; margin: 6px 15px 0 0"
            >
              Clear
            </b-button>
          </b-col>
        </b-row>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { requestService } from '@/_services/request.service';
import Whitelist from '../whitelist_page.vue';

export default {
  name: 'ImportCSVWhitelist',
  components: {
    Whitelist,
  },
  props: ['isThaipost'],
  data() {
    return {
      // currentPage: 1,
      // totalRows: 10,
      // datatable_whitelist: [],
      btnImport: false,
      importCSVData: {},
      file: null,
      csvData: null,
      csvFile: null,
    };
  },
  mounted() {
    // this.totalRows = this.datatable_whitelist.length;
    // this.getDataTable();
    this.permissionUpdate();
  },
  methods: {
    permissionUpdate() {
      const permission = localStorage.getItem('permission');
      let result =
        permission.includes('can_update_whitelist') ||
        permission.includes('is_admin');
      if (!result) {
        this.btnImport = true;
      } else {
        this.btnImport = false;
      }
      // console.log('wl: ', result);
    },
    downloadTemplate() {
      try {
        let csvDataWhitelist = '';
        if (this.isThaipost) {
          csvDataWhitelist =
            'username,subordination,postcode,post_office,callerID,in_service,oos,work_date,work_time' +
            '\r\n';
        } else {
          csvDataWhitelist =
            'username,department,callerID,in_service,oos,work_date,work_time' +
            '\r\n';
        }

        const blob = new Blob(['\ufeff' + csvDataWhitelist], {
          type: 'text/csv;charset=utf-8;',
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'whitelist_template.csv';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } catch (err) {
        console.error('import error', err);
      }
    },
    handleFileUploadWhitelist(e) {
      if (e.target.files[0]) {
        const file = e.target.files[0];
        // this.fileName = e.target.files[0].name;
        const reader = new FileReader();
        reader.onload = (e) => reader.readAsText(file);
        //console.log(e.target.result);
        this.$papa.parse(file, {
          header: true,
          delimiter: ',',
          complete: (results) => {
            let data = results.data;

            // this.csvData = results.meta.fields;
            this.csvData = data;
            // console.log('data', data);
          },
        });
      }
    },
    postImportCSVWhitelist() {
      this.csvData = this.csvData
        .map((e) => {
          let department = '';
          if (this.isThaipost) {
            department =
              e.post_office != '' && e.post_office != undefined
                ? `|${e.post_office}`
                : '|';
          }else{
            department = e.department != '' && e.department != undefined
                ? `|${e.department}`
                : '|'
          }
          // console.log('e1: ', e);

          let obj = {
            username: e.username,
            // domain: e.domain,
            // domain: `${e.domain}|${e.subordination}|${e.postcode}|${e.post_office}|${e.root_call}|${e.in_service}|${e.oos}|${e.work_date}|${e.work_time}`,
            domain: `|${
              e.subordination != '' && e.subordination != undefined
                ? `${e.subordination}`
                : ''
            }${
              e.postcode != '' && e.postcode != undefined
                ? `|${e.postcode}`
                : '|'
            }${department}${e.callerID != '' ? `|${e.callerID}` : '|'}${
              e.in_service != '' && e.in_service != undefined
                ? `|${e.in_service}`
                : '|1545'
            }${e.oos != '' && e.oos != undefined ? `|${e.oos}` : '|1545'}${
              e.work_date != '' && e.work_date != undefined
                ? `|${e.work_date}`
                : '|12345'
            }${
              e.work_time != '' && e.work_time != undefined
                ? `|${e.work_time}`
                : '|08001700'
            }`,
            // grp: 'thaipost',
          };
          return obj;
        })
        .filter(
          (e) => e.username !== '' && !isNaN(e.username)
          // e.username !== '' && !isNaN(e.username) && e.username.length == 9
        );
        this.$bvModal.hide('modal-lg-import-csv-whitelist');
      this.$bvModal.show('loading-modal');

      // console.log('after:', this.csvData);
      let url = `/whitelist`;
      requestService
        .PostFetch(url, this.csvData)
        .then((response) => {
          this.$bvModal.hide('loading-modal');
          // this.$bvModal.hide('modal-lg-import-csv-whitelist');
          this.$emit('getDataTable');
          this.resetForm();
        })
        .catch((error) => {
          // console.log('Error importing CSV:', error);
          this.$swal.fire({
            icon: 'error',
            title: 'มีบางอย่างผิดพลาด',
            text: 'ไม่สามารถเพิ่มข้อมูลได้',
            showConfirmButton: true,
            timer: 1500,
          });
        });
    },
    resetForm() {
      this.file = null;
      this.csvData = null;
      this.csvFile = null;
    },
    // getDataTable() {
    //     let url = api_host+'/whitelist';
    //     // console.log("apiURL",apiURL);
    //     axios.get(url).then(res => {
    //         console.log(res.data.data);
    //         this.datatable_whitelist = res.data.data.rows;
    //     }).catch(error => {
    //         console.log(error)
    //     })
    // },
    clearFilesCSVWhitelist() {
      this.$refs['file-input'].reset();
    },
    loadData() {
      // Logic to load data into the `data` array and Make an Axios GET request or fetch data from an API endpoint
      // this.getDataTable();
    },
  },
};
</script>
<style>
#import-csv-whitlist-btn-z {
  width: 82px;
  height: 24px;
  margin: 2px 0 0 0;
}

#submit-import-whitlist-btn-z {
  color: #3f60fb !important;
  background-color: #dfedff !important;
  border-color: #dfedff !important;
}

#clear-import-whitlist-btn-z {
  color: #f83366 !important;
  background-color: #feeaef !important;
  border-color: #feeaef !important;
}

#b-table-import-whitlist-z thead tr th div {
  margin: 0px 0px 0px 40px;
}

.b-pagination-whitlist {
  size: 10px;
}
</style>
